import createCache from '@emotion/cache';
import { flush, injectGlobal } from '@emotion/css';
import { CacheProvider } from '@emotion/react';
import { Theme, ThemeProvider, createTheme } from '@mui/material/styles';
import Loader from 'component/layout/loader';
import { OldThemeRoutes } from 'config/old-theme-list';
import { RouteTheme } from 'config/route-theme';
import { getDesignTokens } from 'config/theme';
import { getDesignTokensOld } from 'config/theme.old';
import { useAppSelector } from 'hooks/redux-helper';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Navigate, Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { changeIsMobileStatus } from 'state-manager/reducer/home';
import { IsLogin } from 'state-manager/reducer/profile';
import rtlPlugin from 'stylis-plugin-rtl';

const RTLCache = createCache({
	stylisPlugins: [rtlPlugin],
	key: 'rtl',
});

const RTLCacheProvider: FC<{ direction: 'rtl' | 'ltr'; children: ReactNode }> = ({ direction, children }) => {
	if (direction === 'rtl') {
		return <CacheProvider value={RTLCache}>{children}</CacheProvider>;
	} else {
		return children;
	}
};

const RequireAuth: FC<{ children: ReactNode }> = ({ children }) => {
	const isLogin = useAppSelector(state => state.Profile.isLogin);
	const isMobile = useAppSelector(state => state.Home.isMobile);
	const location = useLocation();
	const dispatch = useDispatch();
	if (!isMobile) {
		if (localStorage.getItem('refresh_auth_token') === null) {
			dispatch(IsLogin(false));
		}
	}

	const language = useTranslation().i18n.language;

	return isLogin ? children : <Navigate to={`/${language}/login?callbackUrl=${location.pathname}`} replace />;
};

const RequireNoAuth: FC<{ children: ReactNode }> = ({ children }) => {
	const isLogin = useAppSelector(state => state.Profile.isLogin);
	const location = useLocation();
	const [searchParams] = useSearchParams();

	const { i18n } = useTranslation();
	let language = i18n.language;

	if (searchParams.get('page')) {
		if (!isLogin) {
			return children;
		} else {
			if (searchParams.get('page') === 'buycrypto') {
				return (
					<Navigate
						to={`/${language}/dashboard/profile?page=${searchParams.get('page')}`}
						replace
						state={{ path: location.pathname }}
					/>
				);
			} else {
				return <Navigate to={`/${language}/${searchParams.get('page')}`} replace state={{ path: location.pathname }} />;
			}
		}
	} else {
		return !isLogin ? children : <Navigate to={`/${language}/dashboard/profile`} replace state={{ path: location.pathname }} />;
	}
};

const LanguageHandler = () => {
	const { lng } = useParams();
	const { i18n } = useTranslation();
	const location = useLocation();
	let language = i18n.language;
	let languages = [
		'en',
		'fa',
		'ru',
		'ar',
		// 'de', 'es'
	];

	if (i18n.language.includes('-')) {
		language = i18n.language.split('-')[0];
	}

	useEffect(() => {
		if (!lng || lng.length !== 2) {
			i18n.changeLanguage('en');
			window.location.replace(language + location.pathname);
		}
	}, [location.pathname]);

	useEffect(() => {
		if (lng && lng.length === 2 && lng !== i18n.language) {
			if (languages.includes(lng)) {
				i18n.changeLanguage(lng);
			} else {
				window.location.replace(location.pathname.replace(lng, language));
			}
		}
	}, [lng]);

	return <Outlet />;
};

const ApplyQueryStrings: FC<{ children: ReactNode }> = ({ children }) => {
	const [searchParams] = useSearchParams();
	const dispatch = useDispatch();
	const isMobileAuth = useAppSelector(state => state.Home.isMobileAuth);

	useEffect(() => {
		if (searchParams.get('isMobile') && searchParams.get('isMobile') === 'true') {
			dispatch(changeIsMobileStatus(true));
		}
	}, [searchParams]);

	let isShowChildren = false;

	if (searchParams.get('isMobile') && searchParams.get('isMobile') === 'true') {
		if (isMobileAuth) {
			isShowChildren = true;
		} else {
			isShowChildren = false;
		}
	} else {
		isShowChildren = true;
	}

	return isShowChildren ? children : <Loader />;
};

const ThemeHandler: FC<{ children: ReactNode }> = ({ children }) => {
	const location = useLocation();
	const theme = useAppSelector(state => state.ThemeProvider.theme);
	const [themeToken, setThemeToken] = useState<Theme>();
	const language = useTranslation().i18n.language;
	const direction = language === 'fa' || language === 'ar' ? 'rtl' : 'ltr';
	useEffect(() => {
		console.log(location.pathname);

		const isOldTheme =
			OldThemeRoutes.findIndex(item => location.pathname.toLocaleLowerCase().includes(item.toLocaleLowerCase())) !== -1 ||
			location.pathname === `/${language}`;
		console.log(isOldTheme);

		const findRouteTheme = RouteTheme.find(item => location.pathname.includes(item.route));
		if (findRouteTheme) {
			const token = isOldTheme
				? getDesignTokensOld(findRouteTheme.theme, direction)
				: getDesignTokens(findRouteTheme.theme, direction);

			setThemeToken(createTheme(token));
			document.querySelector('body')!.setAttribute('data-theme', findRouteTheme.theme);
		} else {
			const token = isOldTheme ? getDesignTokensOld(theme, direction) : getDesignTokens(theme, direction);
			setThemeToken(createTheme(token));
			document.querySelector('body')!.setAttribute('data-theme', theme);
		}

		injectGlobal`

			body{
				direction: 'ltr';
				overflow-x: hidden;
			}		 		
			* {
				font-family: ${direction === 'rtl' ? 'Vazirmatn' : 'product sans'}
			}

			p,h1,h2{
				direction: ${direction};
			}

			#navbar_theme{
				pointer-events: ${findRouteTheme ? 'none' : 'initial'};
				opacity: ${findRouteTheme ? '0.5' : '1'};
			}
        `;
		return () => flush();
	}, [theme, location.pathname, language]);

	if (themeToken) {
		return <ThemeProvider theme={themeToken}>{children}</ThemeProvider>;
	}
};

export { ApplyQueryStrings, LanguageHandler, RTLCacheProvider, RequireAuth, RequireNoAuth, ThemeHandler };
