import { ClyCoreConfig } from '@coinlocally/cly-core';
import '@coinlocally/cly-core/css/colors.css';
import 'cly-uikit/css/font/oswald.min.css';
import 'cly-uikit/css/font/product-sans.min.css';
import 'cly-uikit/css/global.min.css';
import { Suspense, lazy, useEffect } from 'react';
import ReactGA from 'react-ga';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Outlet, Route, Routes, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

// components
import Loader from '../../component/layout/loader';
// import BlackFriday from 'shared/components/modal/black-friday';

import ProfileLangModal from 'shared/components/modal/email-lang-modal/profile-lang-modal';
import AppModal from '../../shared/components/app-modal';

// eslint-disable-next-line no-unused-vars
import { useDispatch } from 'react-redux';
import { changeMobileAuthStatus } from 'state-manager/reducer/home';
import { IsLogin } from 'state-manager/reducer/profile';
import { ApplyQueryStrings, LanguageHandler, RequireAuth, ThemeHandler } from './app-helper';
import Router from './router';

// const Dashboard = lazy(() => import('../../route/profile/index'));
const SupportTicket = lazy(() => import('../../route/profile/support-ticket'));
const ActivityLog = lazy(() => import('../../route/profile/activity-log'));
const Notificaiton = lazy(() => import('../../route/profile/notification'));
const DashbordLayout = lazy(() => import('../layout/dashboard-layout'));
const FAQ = lazy(() => import('../../route/other/faq/faq'));
const FAQEarn = lazy(() => import('../../route/other/faq/earn'));
const FAQCrossMargin = lazy(() => import('../../route/other/faq/cross-margin'));
const FAQForex = lazy(() => import('../../route/other/faq/forex-faq'));
const FAQSignal = lazy(() => import('../../route/other/faq/signal'));
const FAQAffiliateAndReferral = lazy(() => import('../../route/other/faq/affiliate-referral'));
const GettingStarted = lazy(() => import('../../route/other/faq/getting-started'));
const DepositWithdraw = lazy(() => import('../../route/other/faq/deposit-withdraw'));
const NotFound = lazy(() => import('../../route/not-found/404'));
const FAQSecurity = lazy(() => import('../../route/other/faq/security'));
const HotIssue = lazy(() => import('../../route/other/faq/hot-issue'));
const FuturesFaq = lazy(() => import('../../route/other/faq/futures'));
const AppSpot = lazy(() => import('../../route/other/faq/app-spot'));
const KYC = lazy(() => import('../../route/profile/KYC/index'));
const KYCCorporation = lazy(() => import('../../route/profile/KYC/corporation-KYC'));
const KYCBasicInformation = lazy(() => import('../../route/profile/KYC/basic-information'));
const KYCUploadDocument = lazy(() => import('../../route/profile/KYC/upload-document'));
const VIP = lazy(() => import('../../route/profile/VIP.jsx'));
const Balances = lazy(() => import('../../route/profile/balances'));
const SmallBalances = lazy(() => import('../../route/profile/small-balances'));
const DepositHistory = lazy(() => import('../../route/profile/history/deposit'));
const WithdrawsHistory = lazy(() => import('../../route/profile/history/withdraws'));
const FiatHistory = lazy(() => import('../../route/profile/history/fiat'));
const TransferHistory = lazy(() => import('../../route/profile/history/transfer'));
const EarnHistory = lazy(() => import('../../route/profile/history/earn'));
const SpotHistory = lazy(() => import('../../route/profile/history/spot'));
const FuturesHistory = lazy(() => import('../../route/profile/history/futures'));
const NFTHome = lazy(() => import('../../route/nft/home'));
const NFTDashboardLayout = lazy(() => import('../layout/nft/dashboard-layout'));
const NFTWallet = lazy(() => import('../../route/nft/profile/wallet'));
const NFTDeposit = lazy(() => import('../../route/nft/profile/deposit'));
const NFTDepositHistory = lazy(() => import('../../route/nft/profile/history/deposit'));
const NFTMarketplace = lazy(() => import('../../route/nft/marketplace'));
const NFTProduct = lazy(() => import('../../route/nft/nft-product'));
const NFTFavorit = lazy(() => import('../../route/nft/profile/favorit'));
const NFTWidthrawHistory = lazy(() => import('../../route/nft/profile/history/widthraw'));
const NFTBiddingHistory = lazy(() => import('../../route/nft/profile/history/bidding'));
const NFTPurchaseHistory = lazy(() => import('../../route/nft/profile/history/purchase'));
const NFTSalesHistory = lazy(() => import('../../route/nft/profile/history/sales'));
const NFTCollected = lazy(() => import('../../route/nft/profile/collected'));
const NFTUSersProfile = lazy(() => import('../../route/nft/profile/users-profile'));
const NFTCollectionDetail = lazy(() => import('../../route/nft/collection-detail'));
const NFTWithdraw = lazy(() => import('../../route/nft/profile/withdraw'));
const Quests = lazy(() => import('../../route/profile/quests'));
const FeeRates = lazy(() => import('../../route/profile/fee-rates'));
const FuturesOverView = lazy(() => import('../../pages/dashboard/overview/futures'));
const SavingHistory = lazy(() => import('../../pages/saving-history'));
const Referees = lazy(() => import('../../route/profile/referees'));

const version = require('../../../package.json').version;

function App() {
	ReactGA.initialize('UA-169230006-1');

	const language = useTranslation().i18n.language;
	const dispatch = useDispatch();

	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
		document.addEventListener('mobile-auth', e => {
			const data = e.detail.data;
			const { type, token, bot } = JSON.parse(data);

			if (type === 'private') {
				localStorage.setItem('access_auth_token', typeof token === 'string' ? token : JSON.stringify(token));
				localStorage.setItem('access_auth_trader_bot_token', typeof bot === 'string' ? bot : JSON.stringify(bot));
				dispatch(IsLogin(true));
				dispatch(changeMobileAuthStatus(true));
			} else {
				dispatch(changeMobileAuthStatus(true));
			}
		});
	}, []);

	function ScrollToTop() {
		const location = useLocation();

		useEffect(() => {
			window.scrollTo(0, 0);
		}, [location.pathname]);

		return null;
	}

	const Redirect = ({ path, type }) => {
		const language = useTranslation().i18n.language;
		const { pair } = useParams();
		const navigate = useNavigate();
		useEffect(() => {
			if (type && type === 'internal') {
				navigate(`/${language}${path}`);
			} else {
				window.location.replace(`/${language}${path}${pair ? `/${pair}` : ''}`);
			}
			// eslint-disable-next-line
		}, []);

		return null;
	};
	const AbsolutePathRedirect = ({ path }) => {
		useEffect(() => {
			window.location.replace(path);
		}, []);

		return null;
	};

	const SignalRedirect = ({ path }) => {
		const language = useTranslation().i18n.language;
		const [searchParams] = useSearchParams();
		const id = searchParams.get('id');
		const base = searchParams.get('base') ?? '';
		const quote = searchParams.get('quote') ?? '';

		if (!id) {
			window.location.replace(`/${language}/404`);
		} else {
			window.location.replace(`/${language}${path}?id=${id}&base=${base}&quote=${quote}`);
		}

		return null;
	};

	const LanguageDetector = () => {
		const { lng } = useParams();
		const { language, changeLanguage } = useTranslation().i18n;

		useEffect(() => {
			if (language !== lng && ['en', 'fa', 'ru', 'ar'].includes(lng)) {
				changeLanguage(lng);
			}
		}, [lng, language]);

		return <Outlet />;
	};

	ClyCoreConfig.APP_VERSION = version;
	ClyCoreConfig.LANGUAGE = language;

	return (
		<BrowserRouter>
			<ThemeHandler>
				{/* <RTLCacheProvider direction={language === 'fa' || language === 'ar' ? 'rtl' : 'ltr'}> */}
				<Suspense fallback={<Loader />}>
					<ScrollToTop />
					<ApplyQueryStrings>
						<Toaster
							position='bottom-right'
							containerStyle={{
								zIndex: 9999999999,
							}}
							containerClassName='toaster_container'
						/>

						<Routes>
							<Route path='' element={<LanguageHandler />} />
							<Route path=':lng/*' element={<NotFound />} />
							<Route path=':lng/404' element={<NotFound />} />
							<Route path=':lng/trade' element={<Redirect path={'/spot'} />} />
							<Route path=':lng/trade/:pair' element={<Redirect path={'/spot'} />} />
							<Route path=':lng/api-key' element={<Redirect path={'/dashboard/api-key'} type='internal' />} />
							<Route path=':lng/referral-program' element={<Redirect path={'/referral'} type='internal' />} />
							<Route path=':lng/profile/edit' element={<Redirect path={'/dashboard/profile'} type='internal' />} />
							<Route path=':lng/dashboard/affiliate' element={<Redirect path={'/affiliate-dashboard'} type='internal' />} />
							<Route path=':lng/wallet' element={<Redirect path={'/balances'} type='internal' />} />

							<Route
								path=':lng/dashboard/forex'
								element={<AbsolutePathRedirect path={'https://coinlocally.forex/en/dashboard'} />}
							/>

							<Route
								path=':lng/dashboard/forex/history/:id'
								element={<AbsolutePathRedirect path={'https://coinlocally.forex/en/dashboard'} />}
							/>
							<Route
								path=':lng/forex-terminal'
								element={<AbsolutePathRedirect path={'https://web.coinlocally.forex/terminal'} />}
							/>
							<Route
								path=':lng/forex-terminal/:id'
								element={<AbsolutePathRedirect path={'https://web.coinlocally.forex/terminal'} />}
							/>
							<Route
								path=':lng/ib-dashboard/*'
								element={<AbsolutePathRedirect path={'https://coinlocally.forex/en/dashboard'} />}
							/>

							<Route path='signal' element={<SignalRedirect path={'/signal/'} type='internal' />} />
							<Route path=':lng' element={<LanguageDetector />}>
								{Router()}

								<Route
									path='account/saving'
									element={
										<RequireAuth>
											<SavingHistory />
										</RequireAuth>
									}
								/>

								<Route path='nft' element={<NFTHome />} />
								<Route path='nft/marketplace' element={<NFTMarketplace />} />
								<Route path='nft/product/:productId' element={<NFTProduct />} />
								<Route path='nft/collection/:collectionId' element={<NFTCollectionDetail />} />
								<Route path='nft/profile/:username' element={<NFTUSersProfile />} />
							</Route>
							{/* TO-DO: remove this comment and all related file due to redesign dashboard and changes the route */}
							{/* <Route
							path='dashboard'
							element={
								<RequireAuth>
									<Dashboard />
								</RequireAuth>
							}
						/> */}
							<Route
								path=':lng/referees'
								element={
									<RequireAuth>
										<Referees />
									</RequireAuth>
								}
							/>
							<Route
								path=':lng/supportTicket'
								element={
									<RequireAuth>
										<SupportTicket />
									</RequireAuth>
								}
							/>
							<Route
								path=':lng/activity-log'
								element={
									<RequireAuth>
										<ActivityLog />
									</RequireAuth>
								}
							/>
							<Route
								path=':lng/notification'
								element={
									<RequireAuth>
										<Notificaiton />
									</RequireAuth>
								}
							/>
							<Route
								path=':lng/VIP'
								element={
									<RequireAuth>
										<VIP />
									</RequireAuth>
								}
							/>
							<Route
								path=':lng/balances'
								element={
									<RequireAuth>
										<Balances />
									</RequireAuth>
								}
							/>
							<Route
								path=':lng/small-balances'
								element={
									<RequireAuth>
										<SmallBalances />
									</RequireAuth>
								}
							/>
							<Route
								path=':lng/rewards'
								element={
									<RequireAuth>
										<Quests />
									</RequireAuth>
								}
							/>
							<Route
								path=':lng/fee-rates'
								element={
									<RequireAuth>
										<FeeRates />
									</RequireAuth>
								}
							/>

							<Route
								path=':lng/KYC'
								element={
									<RequireAuth>
										<DashbordLayout />
									</RequireAuth>
								}
							>
								<Route element={<KYC />} index />
								<Route path='corporation' element={<KYCCorporation />} />
								<Route path='corporation/basic' element={<KYCBasicInformation />} />
								<Route path='corporation/documents' element={<KYCUploadDocument />} />
							</Route>
							<Route path=':lng/FAQ' element={<FAQ />}>
								<Route path='getting-started' element={<GettingStarted />} />
								<Route path='getting-started/:section' element={<GettingStarted />} />
								<Route path='deposit-withdraw' element={<DepositWithdraw />} />
								<Route path='security' element={<FAQSecurity />} />
								<Route path='hot-issue' element={<HotIssue />} />
								<Route path='futures' element={<FuturesFaq />} />
								<Route path='app-spot' element={<AppSpot />} />
								<Route path='earn' element={<FAQEarn />} />
								<Route path='cross-margin' element={<FAQCrossMargin />} />
								<Route path='forex' element={<FAQForex />} />
								<Route path='signal' element={<FAQSignal />} />
								<Route path='affiliate-referral' element={<FAQAffiliateAndReferral />} />
							</Route>
							<Route
								path=':lng/history'
								element={
									<RequireAuth>
										<DashbordLayout />
									</RequireAuth>
								}
							>
								<Route path='deposit' element={<DepositHistory />} />
								<Route path='withdraw' element={<WithdrawsHistory />} />
								<Route path='fiat' element={<FiatHistory />} />
								<Route path='savings' element={<EarnHistory />} />
								<Route path='spot' element={<SpotHistory />} />
								<Route path='futures' element={<FuturesHistory />} />
								<Route path='transfer' element={<TransferHistory />} />
							</Route>
							<Route
								path=':lng/overview'
								element={
									<RequireAuth>
										<DashbordLayout />
									</RequireAuth>
								}
							>
								<Route path='futures' element={<FuturesOverView />} />
							</Route>
							<Route
								path=':lng/nft'
								element={
									<RequireAuth>
										<NFTDashboardLayout />
									</RequireAuth>
								}
							>
								<Route path='wallet' element={<NFTWallet />} />
								<Route path='deposit' element={<NFTDeposit />} />
								<Route path='withdraw' element={<NFTWithdraw />} />
								<Route path='history/deposit' element={<NFTDepositHistory />} />
								<Route path='history/widthraw' element={<NFTWidthrawHistory />} />
								<Route path='history/bidding' element={<NFTBiddingHistory />} />
								<Route path='history/purchase' element={<NFTPurchaseHistory />} />
								<Route path='history/sales' element={<NFTSalesHistory />} />
								<Route path='favorites' element={<NFTFavorit />} />
								<Route path='collected' element={<NFTCollected />} />
							</Route>
						</Routes>
						<ProfileLangModal />

						<AppModal />
					</ApplyQueryStrings>
				</Suspense>
				{/* </RTLCacheProvider> */}
			</ThemeHandler>
		</BrowserRouter>
	);
}

export default App;
